<template>
  <ribbon class="report-ribbon">
    <div class="columns is-multiline">
      <div
        class="report-aside column is-10-tablet is-offset-1-tablet is-4-widescreen is-offset-0-widescreen columns is-multiline"
      >
        <div class="column is-12">
          <h2 class="is-size-2 has-margin-bottom-150">{{ title }}</h2>
          <div class="content is-size-5">
            <slot name="desc" />
          </div>
        </div>
      </div>
      <div
        class="report-main column is-10-tablet is-offset-1-tablet is-8-widescreen is-offset-0-widescreen columns is-multiline"
      >
        <slot name="default" />
      </div>
    </div>
  </ribbon>
</template>

<script>
export default {
  name: "ReportLayout",
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String,
      required: false,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.report-ribbon {
  display: flex;
  min-height: 50vh;
  padding: calc(0.5vw + 4vh) calc(0.5vw + 2vh);
  ::v-deep.container {
    margin: auto;
  }
  @include widescreen() {
    &.is-reversed > ::v-deep.container > .columns {
      flex-direction: row-reverse;
    }
    .report-aside,
    .report-main {
      padding-left: 2rem;
      padding-right: 2rem;
    }
  }
}
</style>
